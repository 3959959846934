<template>
  <div class="data-regie">
    <div class="body-box-rapport body-box-setting">
      <div class="tabs-menu-style pt-4">
        <div
          v-for="item in TabsList"
          :key="item.title"
          @click="handleChangeTabs(item)"
        >
          <div
            class="box-tabs"
            :class="{ 'Active-tabs-style': selectedTabs == item.title }"
          >
            <div class="icon">
              <img :src="item.img" />
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
          <hr class="ligne" />
        </div>
      </div>
      <div class="table-rapport-style w-100-p">
        <div class="doc-content mt-1" v-if="selectedTabs == 'Upload'">
          <div class="form-type">
            <form @submit.prevent="uploadFiles">
              <div class="form-groupe">
                <div class="content-files">
                  <b-form-file
                    v-model="Files"
                    ref="file-type"
                    accept=".pdf"
                    :required="true"
                    placeholder="Aucun fichier selectionné"
                    drop-placeholder="Drop file here..."
                    multiple
                  ></b-form-file>
                </div>
                <div class="file-header-titles mt-3" v-if="Files.length > 0">
                  <div class="file-name-title">Fichiers</div>
                  <div class="file-category-title">Catégories</div>
                </div>
                <div id="upload-file-component">
                  <div
                    class="files-to-upload"
                    v-for="(file, index) in Files"
                    :key="'file' + index"
                  >
                    <p class="file-name">
                      <font-awesome-icon
                        icon="paperclip"
                        class="file-upload-icon"
                      />
                      {{ file.name }}
                    </p>
                    <b-form-select
                      v-model="Types[index]"
                      :options="options"
                      required
                      class="file-category"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="form-groupe mt-3">
                <div class="actionFile">
                  <button
                    type="button"
                    @click="clearFiles"
                    class=" button-cancel-style mt-0"
                  >
                    Annuler</button
                  ><button
                    class="button-succes-style ml-2"
                    size="sm"
                    variant="success"
                    @click="uploadFiles"
                    :disabled="getLoadingUploadFileRegie"
                  >
                    Enregistrer
                    <div class="chargement" v-if="getLoadingUploadFileRegie">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </button>
                </div>
                <div v-if="getErrorLoadingFile" class="error">
                  <div
                    v-for="Errors in getErrorLoadingFile"
                    :key="'Error' + Errors"
                  >
                    <ul v-if="Array.isArray(Errors)">
                      <li
                        v-for="(error, index) in Errors"
                        :key="'error' + index"
                      >
                        {{ error }}
                      </li>
                    </ul>
                    <span v-else>{{ Errors }}</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          class="doc-list mt-1"
          v-if="this.getRegieInfo && selectedTabs == 'Fichier'"
        >
          <b-tabs class="files-tabs" lazy>
            <b-tab
              no-body
              title-link-class="files-tab-title"
              v-for="(categorie, index) in categorieFiles"
              :key="'categorie' + index"
            >
              <template class="counter" #title>
                {{ categorie.text }}
                <span v-if="categorie.value == 'tous'">
                  {{ getAllFiles.length }}
                </span>
                <span v-if="categorie.value == 'KBIS'">
                  {{ getRegieInfo.files.KBIS.length }}
                </span>
                <span v-if="categorie.value == 'Quitus_Fiscal'">
                  {{ getRegieInfo.files.Quitus_Fiscal.length }}
                </span>
                <span v-if="categorie.value == 'Attestation_URSAAF'">
                  {{ getRegieInfo.files.Attestation_URSAAF.length }}
                </span>
              </template>
              <template>
                <span
                  v-if="categorie.value == 'tous'"
                  :class="{ ' top-position-style': index == 0 }"
                >
                  <File
                    v-for="file in getAllFiles"
                    :key="'ALL' + file.id"
                    :file="file"
                    :deleteMethod="deleteRegieFile"
                  ></File>
                </span>
                <span v-if="categorie.value == 'KBIS'">
                  <File
                    v-for="file in getRegieInfo.files.KBIS"
                    :key="'KBIS' + file.id"
                    :file="file"
                    :deleteMethod="deleteRegieFile"
                  ></File>
                </span>
                <span v-if="categorie.value == 'Quitus_Fiscal'">
                  <File
                    v-for="file in getRegieInfo.files.Quitus_Fiscal"
                    :key="'Quitus_Fiscal' + file.id"
                    :file="file"
                    :deleteMethod="deleteRegieFile"
                  ></File>
                </span>
                <span v-if="categorie.value == 'Attestation_URSAAF'">
                  <File
                    v-for="file in getRegieInfo.files.Attestation_URSAAF"
                    :key="'Attestation_URSAAF' + file.id"
                    :file="file"
                    :deleteMethod="deleteRegieFile"
                  ></File>
                </span>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      success: false,
      Files: [],
      Types: [],
      options: [
        { value: 'KBIS', text: 'KBIS' },
        { value: 'Quitus_Fiscal', text: 'Quitus Fiscal' },
        { value: 'Attestation_URSAAF', text: 'Attestation URSAAF' }
      ],
      selectedTabs: 'Upload',
      TabsList: [
        {
          title: 'Upload',
          img: '../image/icon-upload.png'
        },
        {
          title: 'Fichier',
          img: '../image/icon-pdf-file.png'
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'getRegieInfo',
      'getLoadingUploadFileRegie',
      'getErrorLoadingFile'
    ]),
    categorieFiles() {
      return [{ value: 'tous', text: 'Tous' }, ...this.options];
    },
    getAllFiles() {
      if (this.getRegieInfo) {
        return [
          ...this.getRegieInfo.files.Attestation_URSAAF,
          ...this.getRegieInfo.files.KBIS,
          ...this.getRegieInfo.files.Quitus_Fiscal
        ];
      } else {
        return [];
      }
    }
  },

  methods: {
    ...mapActions(['fetchRegieInfo', 'uploadFilesRegie', 'deleteRegieFile']),
    ...mapMutations(['SET_ERROR_IN_LOADING_FILE']),
    clearFiles() {
      this.$refs['file-type'].reset();
      this.Types = [];
      this.SET_ERROR_IN_LOADING_FILE(null);
    },
    async uploadFiles() {
      if (
        this.Files.length > 0 &&
        this.Types.length > 0 &&
        this.Files.length === this.Types.length
      ) {
        var payload = new FormData();
        payload.append('regie_id', this.getRegieInfo.id);
        for (const statesKey in this.Files) {
          payload.append(
            'files[' + statesKey + '][file]',
            this.Files[statesKey]
          );
          payload.append(
            'files[' + statesKey + '][type]',
            this.Types[statesKey]
          );
        }
        const responce = await this.uploadFilesRegie(payload);
        if (responce) {
          this.clearFiles();
        }
      }
    },
    handleChangeTabs(tab) {
      this.selectedTabs = tab.title;
    }
  },
  mounted() {
    this.success = false;
    this.SET_ERROR_IN_LOADING_FILE(null);
    this.fetchRegieInfo();
  },
  components: {
    File: () => import('../component/Files.vue')
  }
};
</script>

<style lang="scss" scoped>
.data-regie {
  padding: 5px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .body-box-rapport {
    height: 526px;
    background-color: white;
  }
  .tabs-menu-style {
    background-color: #f5f4fa;
    .box-tabs {
      background-color: #f5f4fa;
      cursor: pointer;
      &:hover {
        background-color: #9799d6;
      }
    }
    .Active-tabs-style {
      background-color: #4d4bac;
    }
  }
  .table-rapport-style {
    display: inline;
    background-color: #f5f4fa;

    .doc-content {
      display: flex;

      .form-type {
        align-items: center;
        align-content: center;
        justify-content: center;
        min-width: 56%;
        margin: auto;
        .form-groupe {
          .file-header-titles {
            align-items: center;
            padding: 4px 20px;
            display: flex;
            .file-name-title,
            .file-category-title {
              text-align: center;
              color: #495057;
              font-weight: 600;
              border-radius: 6px;
              // background-color: #8d8cb7;
              padding: 3px;
            }
            .file-name-title {
              width: 60%;
              margin-right: 10px;
            }
            .file-category-title {
              width: 40%;
            }
          }
          .files-to-upload {
            display: flex;
            align-items: center;
            padding: 2px 20px;
            .file-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 60%;
              margin-right: 10px;
              text-align: left;
              .file-upload-icon {
                color: #2dabe2;
              }
            }
            .file-category {
              width: 40%;
              font-size: 13px;
              border-radius: 17px;
            }
          }
          .actionFile {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 5px;
            .button-cancel-style {
              background-color: #49505782;
              color: white;
              border: none;
            }
            .chargement {
              margin-left: 5px;
              .spinner-border {
                width: 1rem;
                height: 1rem;
              }
            }
          }
          .error {
            color: red;
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir...';
  background-color: #4d4bac;
  color: white;
  height: 37px;
}
.custom-file-input:lang(en) ~ .custom-file-label {
  border: none;
  border-radius: 20px;
  background-color: #fff;
  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    pointer-events: none;
    margin-top: 5px;
  }
}
.doc-list {
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;

      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        background-color: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 20px;
        padding: 0px 10px;
        padding: 2px 11px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;
        border: none;
        margin-right: 8px;
        .counter {
          color: #858585;
          margin-left: 5px;
        }
        &.active {
          color: white !important;
          border-radius: 20px;
          border: none;
          background-color: #9799d6 !important;
          padding: 2px 11px;
          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}
#upload-file-component {
  overflow-y: auto;
  max-height: 321px;
  position: relative;
}

.tab-content > .active {
  overflow-y: auto;
  max-height: 412px;
  position: relative;
  width: 56%;
  margin: auto;
}
#upload-file-component::-webkit-scrollbar,
.tab-content > .active::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
#upload-file-component::-webkit-scrollbar-track,
.tab-content > .active::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
#upload-file-component::-webkit-scrollbar-thumb,
.tab-content > .active::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}
.top-position-style > .file-content {
  top: -5px;
}
</style>
